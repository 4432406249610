<template>
  <v-container style="max-width: 600px" class="pa-0">
    <v-overlay :value="loading">
      <v-progress-circular :size="70" :width="7" color="orange" indeterminate></v-progress-circular>
    </v-overlay>

    <SpotsComponent mode="spots-page" @loading="rendered($event)"></SpotsComponent>
    <BottomNavigation app></BottomNavigation>
  </v-container>
</template>

<script>
  import BottomNavigation from '@/components/BottomNavigation.vue';
  import SpotsComponent from '@/components/PickupSpots.vue';
  export default {
    name: 'Spots',

    data: () => ({
      loading: true,
      myLatLng: { lat: 69703968240988, lng: 139.7465210587719 },
      spots: null,
      spot: null, //selected spot
    }),

    components: {
      BottomNavigation,
      SpotsComponent,
    },
    
    async created() {},

    async mounted() {},

    methods: {
      rendered(value) {
        this.loading = value;
      },
    },

  };
</script>
